import './src/layout/reset.scss'
import './src/layout/global.scss'
import './src/layout/components/main/components/gen4Agenda/gen4Agenda.scss'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
    console.log('# IntersectionObserver is polyfilled!')
  }
}

// Shortcut to global styles
const style = document.documentElement.style

// Keep track of various heights
const sizeListener = () => style.setProperty('--window-height', window.innerHeight + 'px')
sizeListener()
window.addEventListener('resize', sizeListener)
