// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-news-news-tsx": () => import("./../../../src/templates/news/news.tsx" /* webpackChunkName: "component---src-templates-news-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-partners-partners-tsx": () => import("./../../../src/templates/partners/partners.tsx" /* webpackChunkName: "component---src-templates-partners-partners-tsx" */)
}

